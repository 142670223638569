<template>
  <h1>ForgotPassword.vue</h1>
</template>
<script>
export default {
  name: 'ForgotPassword',
};
</script>

<style scoped lang="scss"></style>
